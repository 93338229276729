// extracted by mini-css-extract-plugin
export var category = "Feed-module--category--DGEwP";
export var description = "Feed-module--description--UXUO2";
export var divider = "Feed-module--divider--3kBgQ";
export var feed = "Feed-module--feed--3dYe7";
export var item = "Feed-module--item--ooOky";
export var link = "Feed-module--link--GNuXE";
export var meta = "Feed-module--meta--ECIPa";
export var more = "Feed-module--more--Axk8-";
export var time = "Feed-module--time--17yla";
export var title = "Feed-module--title--1qA0W";